<template>
    <div>
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title"> Change Password</h4>
              <form action="#" @submit.prevent="handlePasswordChangeForm">
                <div class="form-group">
                  <label for="old_password">Old password</label>
                  <div class="input-group input-group-merge">
                  <input :type="obscureOldPassword ? 'password': 'text' " class="form-control" id="old_password"
                         v-model="oldPassword" required>
                    <div class="input-group-append" data-password="false">
                      <div class="input-group-text" @click="obscureOldPassword = !obscureOldPassword">
                        <span class="password-eye"></span>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="form-group">
                  <label for="new_password">New password</label>

                  <div class="input-group input-group-merge">
                  <input :type="obscureNewPassword ? 'password': 'text' " class="form-control" id="new_password" v-model="newPassword" required>
                    <div class="input-group-append" data-password="false">
                      <div class="input-group-text" @click="obscureNewPassword = !obscureNewPassword">
                        <span class="password-eye"></span>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="form-group">
                  <label for="confirm_password">Confirm password</label>

                  <div class="input-group input-group-merge">
                  <input :type="obscureConfirmPassword ? 'password': 'text' " class="form-control" id="confirm_password" v-model="confirmPassword" required>
                    <div class="input-group-append" data-password="false">
                      <div class="input-group-text" @click="obscureConfirmPassword = !obscureConfirmPassword">
                        <span class="password-eye"></span>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-success">Update password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title"> Change personal details</h4>
              <form action="#" @submit.prevent="handlePersonalInfoChangeForm">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="text" class="form-control" id="email" v-model="email">
                </div>
                <div class="form-group">
                  <label for="phone">Phone number</label>
                  <input type="text" class="form-control" id="phone" v-model="phone">
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-dark">Update info</button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>


<script>
import appConfig from "../../../../../app.config.json";

/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title}`,
    };
  },
  data() {
    return {
      title: "Starter",
      email: '',
      phone: '',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      obscureOldPassword: true,
      obscureNewPassword: true,
      obscureConfirmPassword: true,
    };
  },
  computed: {
  },
  page: {
    title: "RedCabs",
    meta: [{ name: "description", content: appConfig.description }],
  },
  methods: {
    handlePasswordChangeForm(){
      const payload = {
        'old_password': this.oldPassword,
        'password': this.newPassword,
        'password_confirmation': this.confirmPassword
      }
      this.$store.dispatch('changePassword', payload);

    },
    handlePersonalInfoChangeForm(){
      const payload = {
        'email': this.email,
        'phone': this.phone
      }

      this.$store.dispatch('updatePersonalInfo', payload);
    }
  },
  created() {
    const user =  this.$store.getters.GET_USER_INFO
    console.log('user: ', user);
    this.email = user.email;
    this.phone = user.phone;
    this.$store.dispatch('setPageTitle', 'Account Settings')
  }
};
</script>